<template>
  <div class="limiter">
    <div class="container-login100">
      <div class="wrap-login100">
        <form class="login100-form validate-form"
              @submit.native.prevent
        >
					<span class="login100-form-logo">
            <img src="../assets/logo-header-big.png" alt="connectprof logo">
            <!--						<i class="zmdi zmdi-landscape"></i>-->
					</span>

          <span class="login100-form-title p-b-34 p-t-27">
						Bill Giriş
					</span>

          <div class="wrap-input100 validate-input">
            <input class="input100" type="text" v-model="formInline.email" placeholder="E-Posta">
            <span class="focus-input100" data-placeholder="&#xf207;"></span>
          </div>

          <div class="wrap-input100 validate-input">
            <input class="input100" type="password" v-model="formInline.password" placeholder="Şifre">
            <span class="focus-input100" data-placeholder="&#xf191;"></span>
          </div>

          <div class="wrap-input100 validate-input">
            <input class="input100" type="password" v-model="formInline.gaCode" maxlength="6" placeholder="Google Authenticator Kodu">
            <span class="focus-input100" data-placeholder="&#xf191;"></span>
          </div>

          <div class="container-login100-form-btn">
            <button @click="auth(formInline)"
                    :disabled="!(formInline.email !== '' && formInline.password !== '' && formInline.gaCode !== '')"
                    v-if="(formInline.email !== '' && formInline.password !== '' && formInline.gaCode)"
                    class="login100-form-btn">
              Giriş Yap
            </button>
          </div>

          <div class="text-center p-t-90">
            <a class="txt1" href="https://www.connectprof.com" target="_blank">
              © Connectprof
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
  <teleport to="#mTitle">Giriş | Bill</teleport>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Auth",
  data() {
    return {
      formInline: {
        email: "",
        password: "",
        gaCode: ""
      }
    };
  },
  methods: {
    ...mapActions(["auth"])
  }
};
</script>

<style scoped>
@import "../../src/assets/loginpage/fonts/font-awesome-4.7.0/css/font-awesome.min.css";
@import "../../src/assets/loginpage/fonts/iconic/css/material-design-iconic-font.min.css";
@import "../../src/assets/loginpage/css/main.css";

.p-t-27 {
  padding-top: 27px;
}

.p-t-90 {
  padding-top: 90px;
}

.p-b-34 {
  padding-bottom: 34px;
}

.text-center {
  text-align: center;
}

.container-login100 {
  background-image: url("../../src/assets/loginpage/images/bg-01.jpg");
}
</style>
